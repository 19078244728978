@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,100..900;1,100..900&display=swap');

.App {
  text-align: center;
}


html {
  scroll-behavior: smooth;
}

.scroll-container {
  position: relative;
  width: 100%;
  height: 100vh;
  scroll-behavior: smooth;
  overflow: auto;
  scroll-snap-type: y mandatory;
}

.scroll-page {
  width: 100%;
  height: 100vh;
  background-size:  cover;
  scroll-snap-align: start;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.navbar {
  position: relative;
  padding-top: 20px;
  background-color: #af1f75;
  overflow: hidden;
}

/* .navbar::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: 20px;
  background-image: url('snake.svg');
  background-repeat: repeat-x;
} */

body {
  background-color: #000000;
  color: #ea7cbe; 
  font-family: "Chivo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

header h1 {
  color: #ea7cbe;
  font-family: "Chivo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.butterfly-svg {
  position: absolute; 
  top: 10px;            
  right: 10px;         
  width: 200px;       
  height: auto;      
  z-index: 50;  
  transform: rotate(15deg);     
}

.laptop-svg {
  position: absolute; 
  top: 150px;            
  right: 1300px;         
  width: 200px;       
  height: auto;      
  z-index: 10;  
  transform: rotate(330deg);     
}

.heart1-svg {
  position: absolute; 
  top: 600px;            
  right: 50px;         
  width: 150px;       
  height: auto;      
  z-index: 10;  
  transform: rotate(10deg);     
}

.stemmed_flower-svg {
  position: absolute; 
  top: 2280px;            
  right: 1400px;         
  width: 100px;       
  height: auto;      
  z-index: 10;  
  transform: rotate(0deg);     
}

.lamppost-svg {
  position: absolute; 
  top: 3700px;            
  right: 1250px;         
  width: 250px;       
  height: auto;      
  z-index: 10;  
  transform: rotate(0deg);     
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}